var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col"},[(!_vm.resendSuccess && !_vm.resendError)?_c('span',{staticClass:"font-medium mb-2"},[_vm._v("Saada aktivatsioonimeil uuesti")]):_vm._e(),(
      !_vm.$store.state.userData.user.is_email_verified &&
        !_vm.resendSuccess &&
        !_vm.resendError
    )?_c('div',{staticClass:"flex w-1/2 mb-4"},[_c('span',[_vm._v("Juhul kui te ei saanud aktivatsioonimeili, vajutage all asuvat nuppu, et see uuesti saata. Kontrollige kindlasti ka oma rämpspostikausta!")])]):_vm._e(),(
      _vm.$store.state.userData.user.is_email_verified &&
        !_vm.resendSuccess &&
        !_vm.resendError
    )?_c('div',{staticClass:"flex w-1/2 mb-4"},[_c('span',[_vm._v("Teie kontoga seotud meiliaadress on juba kinnitatud!")])]):_vm._e(),(
      !_vm.$store.state.userData.user.is_email_verified &&
        !_vm.resendSuccess &&
        !_vm.resendError
    )?_c('div',{staticClass:"flex"},[_c('button',{staticClass:"new-button-green",on:{"click":_vm.resendActivationEmail}},[_c('span',{staticClass:"icon typcn typcn-mail"}),_c('span',{staticClass:"label"},[_vm._v("Saada")])])]):_vm._e(),(_vm.resendSuccess)?_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"font-medium mb-2"},[_vm._v("Saatmine õnnestus!")]),_c('p',[_vm._v(" Vaata oma postkasti ning järgi kirjas sisalduvaid juhiseid, et oma e-posti aadress aktiveerida. ")])]):_vm._e(),(_vm.resendError)?_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"font-medium mb-2"},[_vm._v("Saatmine ebaõnnestus!")]),_vm._m(0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mobile:px-3"},[_vm._v(" Proovi uuesti ning probleemide jätkudes võta meiega ühendust aadressil "),_c('a',{staticClass:"hover:text-darkgreen",attrs:{"href":"mailto:info@gewodo.com"}},[_vm._v("info@gewodo.com")])])}]

export { render, staticRenderFns }