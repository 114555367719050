<template>
  <div class="flex flex-col">
    <span class="font-medium mb-2" v-if="!resendSuccess && !resendError"
      >Saada aktivatsioonimeil uuesti</span
    >
    <div
      class="flex w-1/2 mb-4"
      v-if="
        !$store.state.userData.user.is_email_verified &&
          !resendSuccess &&
          !resendError
      "
    >
      <span
        >Juhul kui te ei saanud aktivatsioonimeili, vajutage all asuvat nuppu,
        et see uuesti saata. Kontrollige kindlasti ka oma
        rämpspostikausta!</span
      >
    </div>
    <div
      class="flex w-1/2 mb-4"
      v-if="
        $store.state.userData.user.is_email_verified &&
          !resendSuccess &&
          !resendError
      "
    >
      <span>Teie kontoga seotud meiliaadress on juba kinnitatud!</span>
    </div>
    <div
      class="flex"
      v-if="
        !$store.state.userData.user.is_email_verified &&
          !resendSuccess &&
          !resendError
      "
    >
      <button class="new-button-green" @click="resendActivationEmail">
        <span class="icon typcn typcn-mail"></span
        ><span class="label">Saada</span>
      </button>
    </div>
    <div class="flex flex-col" v-if="resendSuccess">
      <span class="font-medium mb-2">Saatmine õnnestus!</span>
      <p>
        Vaata oma postkasti ning järgi kirjas sisalduvaid juhiseid, et oma
        e-posti aadress aktiveerida.
      </p>
    </div>
    <div class="flex flex-col" v-if="resendError">
      <span class="font-medium mb-2">Saatmine ebaõnnestus!</span>
      <p class="mobile:px-3">
        Proovi uuesti ning probleemide jätkudes võta meiega ühendust aadressil
        <a href="mailto:info@gewodo.com" class="hover:text-darkgreen"
          >info@gewodo.com</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  data() {
    return {
      resendSuccess: false,
      resendError: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    resendActivationEmail() {
      this.apiRequest(
        "authentication/user/resend/" +
          this.$store.state.userData.user.id +
          "/",
        "get",
        true
      )
        .then(
          res => {
            if (res.status === 200) {
              this.resendSuccess = true;
            } else {
              this.resendError = true;
            }
          },
          err => {
            if (err) {
              this.resendError = true;
            }
          }
        )
        .catch(err => {
          if (err) {
            this.resendError = true;
          }
        });
    }
  }
};
</script>
